import classNames from "classnames";
import {KeyboardEventHandler, useEffect, useState} from "react";
import {TextInput} from "../input/text";
import {P, TypographyVariant} from "../typography";
import s from "./EditableValue.module.css";

type Props = {
    textClassName?: string;
    className?: string;
    displayedValue?: string;
    value: string;
    mask?: string;
    variant?: TypographyVariant;
    maxLength?: number;
    isActiveHighlight?: boolean;
    isDisabled?: boolean;
    canEmpty?: boolean;
    onValueChange: (value: string) => void;
};

export const EditableValue = (props: Props) => {
    const {
        textClassName,
        className,
        displayedValue,
        value,
        mask,
        variant = "Paragraph/Default/p",
        maxLength = Number.MAX_SAFE_INTEGER,
        isDisabled,
        isActiveHighlight = true,
        canEmpty = false,
        onValueChange,
    } = props;
    const [isEdit, setEdit] = useState(false);

    const [pendingValue, setPendingValue] = useState(value);
    const [unmaskedValue, setUnmaskedValue] = useState(value);
    useEffect(() => {
        setPendingValue(value);
        setUnmaskedValue(value);
    }, [value]);

    const handleEditToggle = () => setEdit(!isEdit);
    const handleInputBlur = () => {
        if (canEmpty || pendingValue.trim().length > 0) {
            onValueChange(pendingValue.trim());
        }

        setEdit(false);
        setPendingValue(value);
        setUnmaskedValue(value);
    };
    const handleKeyDown: KeyboardEventHandler = e => {
        if (e.key === "Enter") {
            handleInputBlur();
        } else if (e.key === "Escape") {
            setEdit(false);
            setPendingValue(value);
            setUnmaskedValue(value);
        }
    };
    return (
        <>
            {!isEdit && (
                <P
                    className={classNames({
                        [s.text]: !isDisabled,
                        [s.textDisabled]: isDisabled,
                        [s.activeHighlight]: isActiveHighlight
                    }, className, textClassName)}
                    variant={variant}
                    color="none"
                    onClick={e => {
                        e.stopPropagation();
                        handleEditToggle();
                    }}>
                    {displayedValue !== undefined ? displayedValue : value}
                </P>
            )}

            {isEdit && (
                <TextInput
                    containerClassName={className}
                    value={unmaskedValue}
                    mask={mask}
                    autoFocus
                    maxLength={maxLength}
                    onBlur={handleInputBlur}
                    onValueChange={(unmasked, value) => {
                        setUnmaskedValue(unmasked);
                        setPendingValue(value);
                    }}
                    onKeyDown={handleKeyDown}
                    onClick={e => e.stopPropagation()}/>
            )}
        </>
    );
};
