import {AuthorizedHttpClient} from "common/net";
import {CitizenKey, GetAllKeyParams, UpdateKeyParams} from "../types";
import {mapPageable, Pageable, PageableResponse} from "../../common/types";

export class KeyRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async create(houseId: string, key: string[]): Promise<string[]> {
        const response = await this.client.post<{ already_existing_keys?: string[] }>(
            `keys/${houseId}`,
            {
                body: {number: key}
            }
        );
        return response.already_existing_keys || [];
    }


    async update(number: string, params: UpdateKeyParams): Promise<CitizenKey> {
        return await this.client.patch<CitizenKey>(
            `keys/${number}`,
            {body: params}
        );
    }

    async findAll(params: GetAllKeyParams): Promise<Pageable<CitizenKey>> {
        const response = await this.client.get<PageableResponse<CitizenKey>>(
            `keys/${params.houseId}`,
            {
                signal: params.signal,
                params: {
                    page: params.page,
                    limit: params.limit,
                    type: params.type,
                    flat: params.flat,
                    search: params.search,
                }
            }
        );
        return mapPageable(response, item => item);
    }

    async delete(number: string): Promise<void> {
        return await this.client.delete(`keys/${number}`);
    }
}
