import {makeAutoObservable} from "mobx";
import {AsyncJob} from "./job";

//TODO: add waiting for job cancel
export class ConfirmationModal<T> {
    isShow: boolean;
    item: T | null;

    private readonly onConfirm: (item: T) => Promise<void>;
    private readonly confirmJob: AsyncJob<typeof ConfirmationModal.prototype._confirm>;

    constructor(onConfirm: (item: T) => Promise<void>) {
        this.isShow = false;
        this.item = null;
        this.onConfirm = onConfirm;
        makeAutoObservable(this, {}, {autoBind: true});

        this.confirmJob = new AsyncJob({job: this._confirm});
    }

    get errorMessage() {
        return this.confirmJob.errorMessage;
    }

    get isJobPending() {
        return this.confirmJob.isPending;
    }

    show(item: T) {
        this.isShow = true;
        this.item = item;
    }

    perform(item: T) {
        this.item = item;
        this.confirmJob.start();
    }

    confirm() {
        this.isShow = false;
        this.confirmJob.start();
    }

    dismissError() {
        this.confirmJob.clearError();
    }

    close() {
        this.isShow = false;
        this.item = null;
    }

    private* _confirm() {
        yield this.onConfirm(this.item!!);
    }
}
