import {VideoDevice, VideoDeviceResponse} from "./VideoDevice";

export interface Camera extends VideoDevice {
    streamName: string;
    streamUrl: string;
}

export interface CameraResponse extends VideoDeviceResponse {
    stream_name: string;
    stream_url: string;
    embed_link: string;
}

export function mapToCamera(body: CameraResponse): Camera {
    return {
        id: body.id,
        name: body.name,
        description: body.description || "",
        streamUrl: body.stream_url || body.embed_link,
        previewUrl: body.preview_url,
        streamName: body.stream_name,
    };
}

export interface UpdateCameraParam {
    name: string;
    description: string;
    streamName: string;
}

export type CreateCameraParam = UpdateCameraParam & { house_id: string; };