import {
    HouseSystem,
    HouseSystemResponse,
    HouseTag,
    HouseTagResponse,
    mapToHouseSystem,
    mapToHouseTag
} from "houses/types";
import {HouseEmployee, mapToTaskType, TaskType, TaskTypeResponse} from "./index";

export enum ScheduledTaskInterval {
    ONCE = "once",
    PER_DAY = "per_day",
    PER_WEEK = "per_week",
    PER_MONTH = "per_month",
    PER_QUARTER = "per_quarter",
    PER_HALF_YEAR = "per_half_year",
    PER_YEAR = "per_year",
}

const titleByInterval: Record<ScheduledTaskInterval, string> = {
    [ScheduledTaskInterval.ONCE]: "Однократно",
    [ScheduledTaskInterval.PER_DAY]: "Раз в день",
    [ScheduledTaskInterval.PER_WEEK]: "Раз в неделю",
    [ScheduledTaskInterval.PER_MONTH]: "Раз в месяц",
    [ScheduledTaskInterval.PER_YEAR]: "Раз в год",
    [ScheduledTaskInterval.PER_QUARTER]: "Раз в квартал",
    [ScheduledTaskInterval.PER_HALF_YEAR]: "Раз в полгода",
};

export function getScheduledTaskTitle(value: ScheduledTaskInterval) {
    return titleByInterval[value];
}

export const possibleScheduledInterval = Object.values(ScheduledTaskInterval) as ScheduledTaskInterval[];

export interface FindAllScheduledTasksParam {
    page?: number;
    limit?: number;
    search?: string;
    companyIds?: number[];
    excludeCompanyIds?: number[];
    houseIds?: number[];
    excludeHouseIds?: number[];
    tagIds?: number[];
    excludeTagIds?: number[];
    systemIds?: number[];
    excludeSystemIds?: number[];
    employeeIds?: number[];
    excludeEmployeeIds?: number[];
    typeIds?: number[];
    excludeTypeIds?: number[];
    creationDateFrom?: number;
    creationDateTo?: number;
    periods?: ScheduledTaskInterval[];
}

export interface ScheduledTask {
    id: string;
    system: HouseSystem;
    interval: ScheduledTaskInterval;
    houseId: number;
    address: string;
    startingDate: Date;
    employee: HouseEmployee;
    type: TaskType;
    description: string;
    tags: HouseTag[];
    company: {
        id: number;
        name: string;
    };
}

export interface CreateScheduledTaskParam {
    systemId: number;
    interval: ScheduledTaskInterval;
    startingDate: Date;
    employeeId: number;
    typeId: number;
    description: string;
}

export interface UpdateScheduledTaskParam extends CreateScheduledTaskParam {
    id: string;
}

export interface ScheduledTaskResponse {
    id: string;
    system: HouseSystemResponse;
    interval: string;
    starting_date: string;
    employee: HouseEmployee;
    type: TaskTypeResponse;
    house_id: number;
    address: string;
    description: string;
    tags: HouseTagResponse[] | null;
    company: {
        id: number;
        name: string;
    };
}

export interface FindScheduledTaskFilterParam {
    companyId: number | null;
    cursor: number | null;
    limit: number;
    search: string;
}

export function mapToScheduledTask(response: ScheduledTaskResponse): ScheduledTask {
    return {
        id: response.id,
        type: mapToTaskType(response.type),
        startingDate: new Date(response.starting_date),
        employee: response.employee,
        interval: response.interval as ScheduledTaskInterval,
        system: mapToHouseSystem(response.system),
        houseId: response.house_id,
        address: response.address,
        description: response.description,
        tags: response.tags ? response.tags.map(mapToHouseTag) : [],
        company: response.company,
    };
}
