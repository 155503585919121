import {FormikErrors, FormikTouched} from "formik/dist/types";
import {useMemo} from "react";

export function useOnlyTouchedErrors<T>(formik: {
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
}) {
    return useMemo(() => {
        const result: FormikErrors<T> = {};
        for (const [key, value] of Object.entries(formik.errors)) {
            if (formik.touched[key as keyof T]) {
                // @ts-ignore
                result[key] = value;
            }
        }
        return result;
    }, [formik.errors]);
}