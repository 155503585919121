import {FindAllIntercoms,} from "citizens/types/ListedIntercom";
import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {
    Camera,
    CameraResponse,
    CreateCameraParam,
    mapToCamera,
    mapToVideoDevice,
    UpdateCameraParam,
    VideoDevice,
    VideoDeviceResponse
} from "../types";

export class CameraRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(houseId: string, params: FindAllIntercoms): Promise<Pageable<VideoDevice>> {
        const response = await this.client.get<PageableResponse<VideoDeviceResponse>>(
            `houses/${houseId}/cameras`,
            {
                signal: params.signal,
                params: {
                    page: params.page + 1,
                    limit: params.limit,
                    search: params.search,
                }
            }
        );

        return mapPageable(response, mapToVideoDevice);
    }

    async findById(id: string, signal: AbortSignal): Promise<Camera> {
        const response = await this.client.get<CameraResponse>(
            `cameras/${id}`,
            {signal}
        );

        return mapToCamera(response);
    }

    async create(params: CreateCameraParam): Promise<string> {
        const response = await this.client.post<{ id: string }>(
            "cameras",
            {
                body: {
                    house_id: params.house_id,
                    name: params.name,
                    stream_name: params.streamName,
                    description: params.description,
                }
            }
        );

        return response.id;
    }

    async put(id: string, params: UpdateCameraParam): Promise<Camera> {
        const response = await this.client.put<CameraResponse>(
            `cameras/${id}`,
            {
                body: {
                    name: params.name,
                    stream_name: params.streamName,
                    description: params.description,
                }
            }
        )

        return mapToCamera(response);
    }
}
