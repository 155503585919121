import {ReactComponent as AssistantWelcoming} from "../../../common/assets/assistant_welcoming_100.svg";
import {P} from "../typography";
import s from "./SuggestionsPopup.module.css";
import {forwardRef} from "react";

export const NotFoundSuggestions = forwardRef<HTMLDivElement>((_, ref) => (
    <div ref={ref} className={s.suggestionsPlaceholder}>
        <AssistantWelcoming/>
        <P color="dark-gray-p200">Нет элементов</P>
    </div>
));

export default NotFoundSuggestions;
