import React, {UIEventHandler, useMemo} from "react";
import {components as SelectComponents} from "react-select";
import {SelectComponentsConfig} from "react-select/dist/declarations/src/components";
import {StylesConfig} from "react-select/dist/declarations/src/styles";
import useRemembered from "../../../../common/hooks/useRemembered";
import IconArrowDown18x19 from "../../../components/icons/IconArrowDown18x19";
import s from "./InputBox.module.css";
import {InputBoxBullet} from "./InputBoxBullet";
import {InputBoxOption} from "./InputBoxOption";
import {
    InputBoxBulletRenderer,
    InputBoxItem,
    InputBoxOptionRenderer,
    InputBoxValueRenderer,
    MAX_INPUT_BOX_MENU_HEIGHT
} from "./types";

const LoadingIndicator = () => (
    <div style={{position: "absolute", right: 35}}>
        <div className={s.loader}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export const DEFAULT_COMPONENTS: SelectComponentsConfig<any, any, any> = {
    IndicatorSeparator: null,
    LoadingIndicator: () => <LoadingIndicator/>,
    DropdownIndicator: (props) => <IconArrowDown18x19 fill={props.isDisabled ? "var(--dark-gray-mian)" : undefined}/>,
    Option: (props) => (
        <InputBoxOption state={{
            item: props.data,
            innerProps: props.innerProps,
            innerRef: props.innerRef,
            isDisabled: props.isDisabled,
            isSelected: props.isSelected,
            isFocused: props.isFocused,
            isMulti: props.isMulti,
        }}/>
    ),
};

const NullComponent = () => null;

export function useInputBoxComponents<T>(
    props: {
        isMulti: boolean,
        isSearchable?: boolean,
        isLoadingMore?: boolean,
        isMultilineLabel?: boolean;
        onDeselect: ((items: InputBoxItem<T>) => void),
        onScrollToBottom?: (() => void) | undefined,
        renderValue: InputBoxValueRenderer<T> | undefined,
        renderOption: InputBoxOptionRenderer<T> | undefined,
        renderBullet: InputBoxBulletRenderer<T> | undefined,
    },
) {
    const {
        isMulti = false,
        isSearchable = true,
        isLoadingMore = false,
        isMultilineLabel = false,
        onScrollToBottom,
        onDeselect,
        renderValue,
        renderBullet,
        renderOption,
    } = props;
    const _onScrollToBottom = useRemembered(onScrollToBottom);
    const _renderValue = useRemembered(renderValue);
    const _renderOption = useRemembered(renderOption);
    const _renderBullet = useRemembered(renderBullet);

    const IndicatorsContainer: typeof DEFAULT_COMPONENTS["IndicatorsContainer"] = useMemo(() => {
        return props => {
            return (
                <SelectComponents.IndicatorsContainer {...props}>
                    {props.children}
                    {isLoadingMore && <LoadingIndicator/>}
                </SelectComponents.IndicatorsContainer>
            );
        };
    }, [isLoadingMore]);

    const MenuList: typeof DEFAULT_COMPONENTS["MenuList"] = useMemo(() => {
        return props => {
            //В react-select есть пропс onMenuScrollToBottom, но он не вызывается, когда пользователь доходит до конца
            //с помощью клавиатуры
            const BOTTOM_SCROLL_THRESHOLD = 30;
            const handleScroll: UIEventHandler<HTMLDivElement> | undefined = _onScrollToBottom.current ? (e) => {
                const target = e.currentTarget;
                if (target.scrollTop > target.scrollHeight - target.offsetHeight - BOTTOM_SCROLL_THRESHOLD) {
                    _onScrollToBottom.current?.();
                }
            } : undefined;

            return (
                <div
                    ref={props.innerRef}
                    {...props.innerProps}
                    className={s.menuList}
                    onScroll={handleScroll}
                    style={{maxHeight: MAX_INPUT_BOX_MENU_HEIGHT}}>
                    {props.children}
                </div>
            );
        }
    }, [_onScrollToBottom]);

    const Input: typeof DEFAULT_COMPONENTS["Input"] = useMemo(() => {
        return props => {
            return (
                <div className={s.inputContainer}>
                    {isMultilineLabel && <p className={s.inputContainerGrow}>{props.value}</p>}
                    <textarea
                        ref={props.innerRef as any}
                        style={{
                            resize: "none",
                            overflow: "hidden",
                            whiteSpace: isMultilineLabel ? undefined : "nowrap",
                            color: props.isDisabled ? "var(--dark-gray-mian)" : "var(--dark-gray-p100)",
                            backgroundColor: "transparent",
                            outline: "none",
                            border: "none",
                        }}
                        rows={1}
                        readOnly={props.readOnly}
                        disabled={props.isDisabled}
                        tabIndex={props.tabIndex}
                        value={props.value}
                        onInput={e => {
                            props.onInput?.(e as any as React.ChangeEvent<HTMLInputElement>);
                        }}
                        onChange={e => {
                            props.onChange?.(e as any as React.ChangeEvent<HTMLInputElement>);
                        }}
                        onFocus={e => {
                            props.onFocus?.(e as any as React.FocusEvent<HTMLInputElement>)
                        }}
                        onBlur={e => {
                            props.onBlur?.(e as any as React.FocusEvent<HTMLInputElement>);
                        }}
                    />
                </div>
            );
        };
    }, [isMultilineLabel]);

    const Option: typeof DEFAULT_COMPONENTS["Option"] = useMemo(() => {
        const render = _renderOption.current;
        if (!render) {
            return undefined;
        }

        return props => {
            return render({
                innerProps: props.innerProps,
                innerRef: props.innerRef,
                item: props.data,
                isSelected: props.isSelected,
                isFocused: props.isFocused,
                isDisabled: props.isDisabled,
                isMulti: props.isMulti,
            });
        };
    }, [_renderOption]);

    const SingleValue: typeof DEFAULT_COMPONENTS["SingleValue"] = useMemo(() => {
        const render = _renderValue.current;
        if (!render) {
            return undefined;
        }

        return props => (
            <SelectComponents.SingleValue {...props}>
                {render({
                    item: props.data,
                })}
            </SelectComponents.SingleValue>
        );
    }, [_renderValue]);

    const MultiValueContainer: typeof DEFAULT_COMPONENTS["MultiValueContainer"] = useMemo(() => {
        const render = _renderBullet.current;
        if (!render) {
            return props => (
                <InputBoxBullet state={{
                    item: props.data,
                    innerProps: props.innerProps,
                    deselectItem: () => onDeselect(props.data),
                }}/>
            );
        }

        return (props) => render({
            innerProps: props.innerProps,
            item: props.data,
            deselectItem: () => onDeselect(props.data),
        });
    }, [_renderBullet, onDeselect]);

    const ValueContainerForMulti: typeof DEFAULT_COMPONENTS["ValueContainer"] = useMemo(() => {
        return props => {
            const children = props.children as (JSX.Element | JSX.Element[])[];

            const badgesElem = children.filter(elem => elem && Array.isArray(elem));
            const placeholderElem = children.filter(elem => elem && (elem as JSX.Element).key === "placeholder");

            const inputElem = children[1];
            return (
                <div className={s.multiValueContainer}>
                    <div style={{position: "relative", width: "100%"}}>
                        {inputElem}
                        {placeholderElem}
                    </div>
                    {badgesElem.length > 0 && (
                        <div className={s.selectedBadges}>
                            {badgesElem}
                        </div>
                    )}
                </div>
            )
        };
    }, []);

    const components: SelectComponentsConfig<any, any, any> = {
        ...DEFAULT_COMPONENTS,
        IndicatorsContainer,
        MenuList,
        Input,
        MultiValueContainer,
    };

    if (Option) {
        components.Option = Option;
    }

    if (SingleValue) {
        components.SingleValue = SingleValue;
    } else if (isSearchable) {
        components.SingleValue = NullComponent;
    }

    if (isMulti) {
        components.ValueContainer = ValueContainerForMulti;
    }

    return components;
}

export function bakeSelectStyles(isMultilineLabel: boolean, menuWidth: number | undefined): StylesConfig<any, any, any> {
    return {
        multiValue: (base) => ({
            ...base,
            borderRadius: undefined,
            margin: 0,
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 1001,
        }),
        control: (base, {isFocused}) => ({
            ...base,
            fontSize: 14,
            cursor: "text",
            color: "var(--dark-gray-p100)",
            fontFamily: "var(--font)",
            backgroundColor: "var(--dark-gray-p400)",
            borderRadius: "10px",
            borderWidth: "1px",
            borderColor: isFocused ? "var(--dark-white)!important" : "var(--dark-gray-p400)!important",
            padding: "5px 10px",
            boxShadow: "none",
            transition: "none",
            minHeight: "37px",
            height: "100%",
            flexFlow: "initial",
        }),
        singleValue: (base, state) => ({
            ...base,
            color: state.isDisabled ? "var(--dark-gray-mian)" : "var(--dark-gray-p100)",
            whiteSpace: isMultilineLabel ? "break-spaces" : "nowrap",
        }),
        placeholder: (base, state) => ({
            ...base,
            color: state.isDisabled ? "var(--dark-gray-mian)" : "var(--dark-gray-p100)",
            position: state.isMulti ? "absolute" : undefined,
            top: state.isMulti ? 0 : undefined,
        }),
        input: (base, state) => ({
            ...base,
            width: "0",
            color: "var(--dark-gray-p100)",
            position: state.isMulti ? "absolute" : undefined,
            top: state.isMulti ? 0 : undefined,
        }),
        valueContainer: (base, state) => ({
            ...base,
            display: state.isMulti ? "flex" : "grid",
            flexDirection: "column-reverse",
            alignContent: "flex-start",
            padding: 0,
            rowGap: 5,

        }),
        menu: (base) => ({
            ...base,
            minWidth: menuWidth,
            transform: "translate(-50%)",
            left: "50%",
            backgroundColor: "var(--dark-gray-p400)",
            borderRadius: 10,
            padding: 10,
        }),
        menuList: (base) => ({
            ...base,
            paddingRight: 10,
            "::-webkit-scrollbar-thumb": {
                backgroundColor: "var(--dark-gray-p50)",
                borderColor: "red",
                borderWidth: 0,
            },
            "::-webkit-scrollbar": {
                backgroundColor: "var(--dark-gray-mian)",
                borderRadius: 11,
                width: 8,
            }
        }),
        noOptionsMessage: base => ({
            ...base,
            fontFamily: "var(--font)",
        }),
        loadingMessage: base => ({
            ...base,
            fontFamily: "var(--font)",
        }),
    }
}

export function useInputBoxStyles(isMultilineLabel: boolean | undefined, menuWidth: number | undefined) {
    return useMemo(() => {
        return bakeSelectStyles(isMultilineLabel || false, menuWidth);
    }, [isMultilineLabel, menuWidth]);
}