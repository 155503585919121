import {Citizen, CitizenResponse, mapToCitizen} from "citizens/types";

export interface ListedApartmentResponse {
    flat_id: string;
    owner: string | null;
    address: string;
    owner_avatar: string | null;
    number: string;
    citizens_amount: number;
    groups: string | null;
    is_cellphone_disabled: boolean;
}

export interface ListedApartment {
    id: string;
    owner: string | null;
    address: string;
    ownerAvatar: string | null;
    number: string;
    citizenAmount: number;
    groups: string | null;
    isCellphoneDisabled: boolean;
}

export interface FindAllApartmentParams {
    houseId: string;
    page: number;
    limit: number;
    search?: string;
    isHideEmpty?: boolean;
    signal: AbortSignal;
}

export interface UpdateApartmentParams {
    houseId: string;
    apartment: string;
    isCellphoneDisabled: boolean;
}

export function mapToListedApartment(model: ListedApartmentResponse): ListedApartment {
    return {
        id: model.flat_id,
        address: model.address,
        owner: model.owner?.trim() || null,
        ownerAvatar: model.owner_avatar,
        groups: model.groups,
        number: model.number,
        citizenAmount: model.citizens_amount,
        isCellphoneDisabled: model.is_cellphone_disabled,
    }
}

export interface ApartmentResponse {
    address: string;
    number: string;
    citizens_amount: number;
    groups: string | null;
    is_cellphone_disabled: boolean;
    accesses: CitizenResponse[],
}

export interface Apartment {
    address: string;
    number: string;
    citizenAmount: number;
    groups: string | null;
    isCellphoneDisabled: boolean;
    accesses: Citizen[],
}


export function mapToApartment(model: ApartmentResponse): Apartment {
    return {
        address: model.address,
        groups: model.groups,
        number: model.number,
        citizenAmount: model.citizens_amount,
        isCellphoneDisabled: model.is_cellphone_disabled,
        accesses: model.accesses.map(mapToCitizen)
    }
}