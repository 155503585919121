export interface VideoDevice {
    id: string;
    name: string;
    description: string;
    previewUrl: string;
}

export interface VideoDeviceResponse {
    id: string;
    name: string;
    preview_url: string;
    description?: string;
}

export function mapToVideoDevice(model: VideoDeviceResponse): VideoDevice {
    return {
        id: model.id,
        name: model.name,
        description: model.description || "",
        previewUrl: model.preview_url,
    };
}
