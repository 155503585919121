import {P} from "../typography";
import s from "./ContextMenu.module.css";
import {useDismissMenuContext} from "./DismissMenuContext";
import classNames from "classnames";

type Props = {
    children: string;
    isDanger?: boolean;
    onClick: () => void;
}

export const ContextMenuItem = (props: Props) => {
    const {children, isDanger, onClick} = props;
    const dismissMenu = useDismissMenuContext();
    const handleClick = () => {
        dismissMenu();
        onClick();
    };
    return (
        <P className={classNames({
            [s.menuItem]: true,
            [s.menuItemDanger]: isDanger,
        })} onClick={handleClick}>
            {children}
        </P>
    );
};
