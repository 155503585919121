import {mapToTaskStatus, TaskStatus, TaskStatusResponse} from "./TaskStatus";

export enum TaskEventType {
    COMMENT,
    CHANGE_STATUS,
    AUTO_GENERATED,
    CHANGE_EXECUTOR,
    CHANGE_PAYED_DATE,
    CHANGE_ORDERED_DATE,
    CHANGE_RECEIVED_DATE,
    CHANGE_COMPANY,
    CHANGE_TYPE,
    CHANGE_PLANNING_DATE,
    ADD_RELATED_TASK = 12,
    REMOVE_RELATED_TASK = 13,
    DELEGATE_TO_COMPANY = 14,
    WITHDRAW_DELEGATION = 15,
    CHANGE_SYSTEM = 16,
    DELEGATE_FROM_COMPANY = 17,
    WITHDRAW_DELEGATION_FROM_COMPANY = 18,
    CHANGE_CREATOR = 21,
}

export interface TaskEventResponse {
    id: number;
    type: TaskEventType;
    is_in_delegated_task: boolean;
    transfer_status: null | "sent" | "received";
    citizen_transfer_status: null | "sent" | "received";
    user_name: string;
    avatar: string;
    files: CommentAttachmentResponse[];
    creation_date: number;
    description?: string;
    from?: string | number;
    to?: string | number;
    from_status?: TaskStatusResponse;
    to_status?: TaskStatusResponse;
    to_company?: string;
    delegated_task_num?: number;
    number?: string;
    program_name?: string;
}

export interface CommentAttachmentResponse {
    uri: string;
    preview_uri: string;
    size_bytes: number;
    type_name: string;
    name: string;
    type: string;
    typeName: string;
}

export interface TaskEvent {
    id: number;
    isInDelegatedTask: boolean;
    type: TaskEventType;
    authorName: string;
    avatarUrl: string;
    creationDate: Date;
}

export interface ChangeStatusEvent extends TaskEvent {
    type: TaskEventType.CHANGE_STATUS;
    from: TaskStatus | null;
    to: TaskStatus;
}

export interface ChangeExecutorEvent extends TaskEvent {
    type: TaskEventType.CHANGE_EXECUTOR;
    from: string | null;
    to: string;
}

export interface ChangeCreatorEvent extends TaskEvent {
    type: TaskEventType.CHANGE_CREATOR;
    from: string | null;
    to: string;
}

export interface ChangePurchaseDateEvent extends TaskEvent {
    type: TaskEventType.CHANGE_PAYED_DATE
        | TaskEventType.CHANGE_ORDERED_DATE
        | TaskEventType.CHANGE_RECEIVED_DATE;
    from: Date | null;
    to: Date;
}

export interface ChangePlanningDateEvent extends TaskEvent {
    type: TaskEventType.CHANGE_PLANNING_DATE;
    from: Date | null;
    to: Date | null;
}

export interface ChangeCompanyEvent extends TaskEvent {
    type: TaskEventType.CHANGE_COMPANY;
    from: string | null;
    to: string;
}

export interface ChangeTypeEvent extends TaskEvent {
    type: TaskEventType.CHANGE_TYPE;
    from: string | null;
    to: string;
}

export interface ChangeRelatedTaskEvent extends TaskEvent {
    type: TaskEventType.ADD_RELATED_TASK | TaskEventType.REMOVE_RELATED_TASK;
    number: string;
    programName: string;
}

export interface DelegateEvent extends TaskEvent {
    type: TaskEventType.DELEGATE_TO_COMPANY | TaskEventType.DELEGATE_FROM_COMPANY;
    company: string;
    delegatedTaskNum: number;
}

export interface WithdrawDelegationEvent extends TaskEvent {
    type: TaskEventType.WITHDRAW_DELEGATION | TaskEventType.WITHDRAW_DELEGATION_FROM_COMPANY;
}

export interface ChangeSystemEvent extends TaskEvent {
    type: TaskEventType.CHANGE_SYSTEM;
    from: string | null;
    to: string;
}

export interface CommentAttachment {
    type: string;
    typeName: string;
    name: string;
    uri: string;
    previewUri: string;
    sizeInBytes: number;
}

export interface CommentEvent extends TaskEvent {
    type: TaskEventType.COMMENT | TaskEventType.AUTO_GENERATED;
    attachments: CommentAttachment[];
    message: string;
    transferStatus: null | "can_transfer" | "sent" | "received";
    citizenTransferStatus: null | "can_transfer" | "sent" | "received";
}

export type AnyTaskEvent =
    CommentEvent
    | ChangeStatusEvent
    | ChangeExecutorEvent
    | ChangePurchaseDateEvent
    | ChangePlanningDateEvent
    | ChangeCompanyEvent
    | ChangeTypeEvent
    | ChangeRelatedTaskEvent
    | DelegateEvent
    | WithdrawDelegationEvent
    | ChangeSystemEvent
    | ChangeCreatorEvent;

export interface CommentAttachmentParams {
    type: string;
    uri: string;
    sizeBytes: number;
    name: string;
}

export function mapToTaskEvent(entity: TaskEventResponse): AnyTaskEvent | null {
    const event: TaskEvent = {
        id: entity.id,
        type: entity.type,
        isInDelegatedTask: entity.is_in_delegated_task,
        authorName: entity.user_name,
        avatarUrl: entity.avatar,
        creationDate: new Date(entity.creation_date * 1000),
    };

    switch (entity.type) {
        case undefined:
        case TaskEventType.COMMENT:
        case TaskEventType.AUTO_GENERATED:
            return {
                ...event,
                type: entity.type ?? TaskEventType.COMMENT,
                message: entity.description!!,
                attachments: entity.files ? entity.files.map(mapToCommentAttachment) : [],
                transferStatus: entity.transfer_status,
                citizenTransferStatus: entity.citizen_transfer_status,
            };
        case TaskEventType.CHANGE_STATUS:
            return {
                ...event,
                type: TaskEventType.CHANGE_STATUS,
                from: entity.from_status ? mapToTaskStatus(entity.from_status as TaskStatusResponse) : null,
                to: mapToTaskStatus(entity.to_status as TaskStatusResponse),
            };
        case TaskEventType.CHANGE_EXECUTOR:
            return {
                ...event,
                type: TaskEventType.CHANGE_EXECUTOR,
                from: entity.from as string,
                to: entity.to as string,
            }
        case TaskEventType.CHANGE_CREATOR:
            return {
                ...event,
                type: TaskEventType.CHANGE_CREATOR,
                from: entity.from as string,
                to: entity.to as string,
            }
        case TaskEventType.CHANGE_PAYED_DATE:
        case TaskEventType.CHANGE_ORDERED_DATE:
        case TaskEventType.CHANGE_RECEIVED_DATE:
            return {
                ...event,
                type: entity.type,
                from: entity.from === null ? null : new Date((entity.from as number) * 1000),
                to: new Date((entity.to as number) * 1000),
            }
        case TaskEventType.CHANGE_PLANNING_DATE:
            return {
                ...event,
                type: TaskEventType.CHANGE_PLANNING_DATE,
                from: entity.from ? new Date((entity.from as number) * 1000) : null,
                to: entity.to ? new Date((entity.to as number) * 1000) : null,
            }
        case TaskEventType.CHANGE_COMPANY:
            return {
                ...event,
                type: TaskEventType.CHANGE_COMPANY,
                from: entity.from as string,
                to: entity.to as string,
            }
        case TaskEventType.CHANGE_TYPE:
            return {
                ...event,
                type: TaskEventType.CHANGE_TYPE,
                from: entity.from as string,
                to: entity.to as string,
            }
        case TaskEventType.ADD_RELATED_TASK:
        case TaskEventType.REMOVE_RELATED_TASK:
            return {
                ...event,
                type: entity.type,
                number: entity.number!!,
                programName: entity.program_name!!,
            }
        case TaskEventType.DELEGATE_TO_COMPANY:
        case TaskEventType.DELEGATE_FROM_COMPANY:
            return {
                ...event,
                type: entity.type,
                company: entity.to_company!!,
                delegatedTaskNum: entity.delegated_task_num!!,
            }
        case TaskEventType.WITHDRAW_DELEGATION:
        case TaskEventType.WITHDRAW_DELEGATION_FROM_COMPANY:
            return {
                ...event,
                type: entity.type,
            }
        case TaskEventType.CHANGE_SYSTEM:
            return {
                ...event,
                type: entity.type,
                from: entity.from as string,
                to: entity.to as string,
            }
        default:
            return null;
    }
}

export type CreateCommentParams = {
    message: string;
    attachments: CommentAttachmentParams[];
}

function mapToCommentAttachment(entity: CommentAttachmentResponse): CommentAttachment {
    return {
        type: entity.type,
        name: entity.name,
        uri: entity.uri,
        previewUri: entity.preview_uri,
        sizeInBytes: entity.size_bytes,
        typeName: entity.type_name,
    };
}
